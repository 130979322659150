import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Calendar, CheckSquare, Square, Camera, X, ChevronLeft, ChevronRight, Check as CheckIcon } from 'lucide-react';
import Confetti from 'react-confetti';
import imageCompression from 'browser-image-compression';

const CatCareSchedule = () => {
  const [schedule, setSchedule] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [tempNotes, setTempNotes] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedDayImages, setSelectedDayImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    fetchSchedule();
  }, []);

  const fetchSchedule = async () => {
    try {
      const response = await axios.get('/api/schedule');
      console.log('Fetched schedule data:', response.data);
      setSchedule(response.data.map(day => {
        console.log('Processing day:', day.date, 'Images:', day.images ? day.images.length : 0);
        return {
          ...day,
          notes: day.notes || [],
          images: day.images || []
        };
      }));
    } catch (error) {
      console.error('Error fetching schedule:', error.response ? error.response.data : error.message);
    }
  };

  const toggleChecked = async (id, checked) => {
    // Immediately update the local state
    setSchedule(prevSchedule => 
      prevSchedule.map(day => 
        day._id === id ? { ...day, checked: !checked } : day
      )
    );

    try {
      await axios.post(`/api/schedule/${id}`, { checked: !checked });
      // Optionally refetch the schedule to ensure sync with server
      // fetchSchedule();
      if (!checked) {
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 3000);
      }
    } catch (error) {
      console.error('Error updating schedule:', error);
      // Revert the change if the API call fails
      setSchedule(prevSchedule => 
        prevSchedule.map(day => 
          day._id === id ? { ...day, checked: checked } : day
        )
      );
    }
  };

  const appendNote = async (id) => {
    if (tempNotes[id] && tempNotes[id].trim()) {
      try {
        await axios.post(`/api/schedule/${id}/note`, { note: tempNotes[id] });
        setTempNotes(prev => ({ ...prev, [id]: '' }));
        fetchSchedule();
      } catch (error) {
        console.error('Error appending note:', error);
      }
    }
  };

  const handleImageUpload = async (id, event) => {
    setIsUploading(true);
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      console.log(`${files.length} file(s) selected`);
      
      const uploadPromises = files.map(async (file) => {
        console.log('Processing file:', file.name, 'Size:', file.size, 'Type:', file.type);
        try {
          const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1024,
            useWebWorker: true
          };
          const compressedFile = await imageCompression(file, options);
          console.log('Compressed file size:', compressedFile.size);
          
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = async () => {
              const base64String = reader.result;
              console.log('Base64 string length:', base64String.length);
              try {
                const response = await axios.post(`/api/schedule/${id}/image`, { image: base64String });
                console.log('Server response:', response.data);
                resolve();
              } catch (error) {
                console.error('Error uploading image:', error.response ? error.response.data : error.message);
                reject(error);
              }
            };
            reader.onerror = (error) => {
              console.error('FileReader error:', error);
              reject(error);
            };
            reader.readAsDataURL(compressedFile);
          });
        } catch (error) {
          console.error('Error compressing image:', error);
          throw error;
        }
      });

      try {
        await Promise.all(uploadPromises);
        console.log('All images uploaded successfully');
        fetchSchedule();
      } catch (error) {
        console.error('Error uploading one or more images:', error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const openImageGallery = (images, index) => {
    setSelectedDayImages(images);
    setSelectedImageIndex(index);
    setSelectedImage(images[index]);
  };

  const closeImageGallery = () => {
    setSelectedImage(null);
    setSelectedDayImages([]);
    setSelectedImageIndex(0);
  };

  const navigateGallery = (direction) => {
    const newIndex = (selectedImageIndex + direction + selectedDayImages.length) % selectedDayImages.length;
    setSelectedImageIndex(newIndex);
    setSelectedImage(selectedDayImages[newIndex]);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-400 via-pink-500 to-red-500 p-4 sm:p-6 flex items-center justify-center">
      <div className="bg-white rounded-lg p-4 sm:p-6 md:p-8 w-full max-w-md">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-4 text-center">🐱 Cat Care Schedule 🐱</h2>
        {schedule.map((day) => (
          <div key={day._id} className={`mb-3 p-3 rounded-md flex flex-col transition-all duration-300 ease-in-out ${day.checked ? 'bg-green-100' : 'bg-gray-100'}`}>
            <div className="flex items-center">
              <Calendar className="mr-3 text-blue-500 w-6 h-6" />
              <div className="flex-grow">
                <p className="font-semibold">{day.date}</p>
                <p className="text-gray-700">Caretaker: {day.caretaker}</p>
              </div>
              <div className="flex items-center">
                <span className="mr-2 text-sm text-gray-600">Complete</span>
                <button 
                  onClick={() => toggleChecked(day._id, day.checked)}
                  className="focus:outline-none transform transition-transform duration-200 hover:scale-110"
                  aria-label={day.checked ? "Mark as incomplete" : "Mark as complete"}
                >
                  <div className={`w-6 h-6 rounded-md flex items-center justify-center transition-colors duration-200 ease-in-out ${day.checked ? 'bg-green-500' : 'bg-gray-200'}`}>
                    {day.checked ? (
                      <CheckIcon className="text-white w-4 h-4 checkmark-animate" />
                    ) : (
                      <div className="w-4 h-4" />
                    )}
                  </div>
                </button>
              </div>
            </div>
            <div className="mt-2 space-y-2">
              <div className="flex flex-wrap items-center -mx-1">
                <div className="w-full sm:w-auto sm:flex-grow px-1 mb-2 sm:mb-0">
                  <input
                    type="text"
                    placeholder="Add a note"
                    value={tempNotes[day._id] || ''}
                    onChange={(e) => setTempNotes(prev => ({ ...prev, [day._id]: e.target.value }))}
                    className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="flex w-full sm:w-auto px-1">
                  <button
                    onClick={() => appendNote(day._id)}
                    className="flex-grow sm:flex-grow-0 p-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 mr-2"
                  >
                    Add Note
                  </button>
                  <label className="p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-pointer">
                    <Camera className="w-5 h-5" />
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      className="hidden"
                      onChange={(e) => handleImageUpload(day._id, e)}
                    />
                  </label>
                </div>
              </div>
              {day.images && day.images.length > 0 && (
                <div className="mt-2 overflow-x-auto">
                  <div className="flex space-x-2 pb-2">
                    {day.images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Uploaded ${index + 1}`}
                        className="w-16 h-16 object-cover rounded cursor-pointer flex-shrink-0"
                        onClick={() => openImageGallery(day.images, index)}
                      />
                    ))}
                  </div>
                </div>
              )}
              <div className="mt-2">
                {day.notes && day.notes.map((note, index) => (
                  <p key={index} className="text-sm text-gray-600">{note}</p>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      {showConfetti && (
        <div className="fixed inset-0 pointer-events-none z-50">
          <Confetti width={window.innerWidth} height={window.innerHeight} />
        </div>
      )}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <img src={selectedImage} alt="Gallery view" className="max-w-full max-h-[90vh] object-contain" />
            <button
              onClick={closeImageGallery}
              className="absolute top-2 right-2 text-white bg-red-500 rounded-full p-1"
            >
              <X size={24} />
            </button>
            <button
              onClick={() => navigateGallery(-1)}
              className="absolute left-2 top-1/2 transform -translate-y-1/2 text-white bg-gray-800 bg-opacity-50 rounded-full p-1"
            >
              <ChevronLeft size={24} />
            </button>
            <button
              onClick={() => navigateGallery(1)}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white bg-gray-800 bg-opacity-50 rounded-full p-1"
            >
              <ChevronRight size={24} />
            </button>
          </div>
        </div>
      )}
      {isUploading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-md">
            <p>Uploading images...</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default CatCareSchedule;
